<template>
  <div class="content">
    <Nav></Nav>
    <Navbj></Navbj>
    <div class="cont_inform">
      <div><img src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/9.png" alt="">首页 >  <span>隐私政策</span></div>
    </div>
    <div class="cont_policy">
       <div v-html="list.cont">

       </div>
    </div>

    <Btm></Btm>
  </div>
</template>

<script>
import Nav from "@/components/pc-Nav/index"
import Btm from "@/components/pc-Btmfooter/index"
import Navbj from "@/components/pc-Nav/nav_bj"
import {privacy} from "@/api/request/all";
import {mapState} from "vuex";
export default {
  name: "privacy",
  data(){
    return{
      list:''
    }
  },
  components:{
    Nav,
    Navbj,
    Btm
  },
  computed: {
    ...mapState(['user','seo']),
  },
  metaInfo() {
    return {
      title: this.seo[3].title || this.user.indextitle,
      meta: [
        { name: 'description', content: this.seo[3].description ||this.user.drp},
        { name: 'keywords', content:this.seo[3].keywords ||this.user.seo}
      ]
    };
  },
  mounted() {
    this.policy()
  },
  methods:{
    policy(){
      let list={};
      privacy(list).then((res)=>{
         if (res.status == 1){
           this.list=res.data;
         }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.content{

  .cont_inform{
    width: 100%;
    height: 51px;
    background: #EEEEEE;
    div{
      width: 1243px;
      margin:0 auto;
      height: 51px;
      line-height: 51px;
      img{
        width: 16px;
        height: 20px;
        vertical-align: sub;
        margin-right: 9px;
        position: relative;
        top: -2px;
      }
      font-weight: 500;
      font-size: 18px;
      color: #666666;
      span{
        color: #222222;
      }
    }
  }
  .cont_policy{
    width: 100%;
    background: #FFFFFF;
    padding: 30px 0;
    div{
      width: 1243px;
      margin: 0 auto;
    }
  }

}
</style>